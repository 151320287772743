/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import WithdrawForm from './components/WithdrawForm';
import QueryString from './interfaces/QueryString';

import WithdrawFlowUserDetailsResponse from './interfaces/WithdrawFlowUserDetailsResponse';
import { BetmateApiBaseUrl } from './config/Urls';


const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(1),
    },
  },
}));

export default function Checkout() {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [betmateAccessToken, setBetmateAccessToken] = useState<string | null>(null);
  const [betmateUsername, setBetmateUsername] = useState<string | null>(null);
  const [betmateUserForename, setBetmateUserForename] = useState<string>("");
  const [betmateUserSurname, setBetmateUserSurname] = useState<string>("");

  const [amountToWithdraw, setAmountToWithdraw] = useState<number | null>(null);

  useAsyncEffect(async () => {
    try {
      const queryString = require("query-string");
      const queryStringObject = queryString.parse(location.search) as QueryString;
      const { accessToken, amount } = queryStringObject;

      if (!accessToken) {
        setErrorMessage("Failed to retrieve access token, please try again.");
        return;
      }
      setBetmateAccessToken(accessToken);

      if (!amount) {
        setErrorMessage("Failed to retrieve withdraw amount, please try again.");
        return;
      }

      let numericAmount = Number(amount);
      setAmountToWithdraw(numericAmount);

      const minimumWithdrawal = Number(20.00);
      if (!numericAmount || numericAmount < minimumWithdrawal) {
        setErrorMessage("The minimum withdraw amount is £20");
        return;
      }

      const detailsEndpoint = BetmateApiBaseUrl + "/GetUserWithdrawFlowDetails";
      const detailsRequestInit: RequestInit = {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + accessToken,
        },
      };

      let detailsResponse = await fetch(detailsEndpoint, detailsRequestInit);
      let detailsTypedResponse = await detailsResponse.json() as WithdrawFlowUserDetailsResponse;

      let username: string | null = null;
      let forename: string = "";
      let surname: string = "";

      let accountBalance: number = 0.00;

      if (
        detailsTypedResponse && 
        detailsTypedResponse.success && 
        detailsTypedResponse.data
      ) {
        username = detailsTypedResponse.data.username;
        forename = detailsTypedResponse.data.forename;
        surname = detailsTypedResponse.data.surname;
        accountBalance = detailsTypedResponse.data.accountBalance;

        setBetmateUsername(username);
        setBetmateUserForename(forename);
        setBetmateUserSurname(surname);
      } 
      
      if (!username) {
        setErrorMessage("Failed to get user details, please try again.");
        return;
      }

      if (numericAmount && accountBalance < numericAmount) {
        setErrorMessage("You cannot withdraw more than your available balance.");
        return;
      }
    }
    catch(e) {
      setErrorMessage("Something went wrong, please try again.");
    } finally {
      setIsLoading(false);
    }

  }, []);


  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>

          {/* Loading spinner */}
          {
            isLoading === true && 
            <Grid container justifyContent="center"> 
              <CircularProgress size={100} />
            </Grid>
          }

          {/* Error message form */}
          {
            isLoading === false && 
            errorMessage !== null && 
            <React.Fragment>
              <p>
                {errorMessage}
              </p>
            </React.Fragment>
          }

          {/* Withdraw form */}
          {
            isLoading === false && 
            errorMessage === null &&
            <React.Fragment>
              <p>
                <strong>Amount:</strong> £{amountToWithdraw?.toFixed(2)} GBP<br/>
                <strong>Username:</strong> {betmateUsername}<br/>
                <strong>Merchant:</strong> Betmate Ltd
              </p>
              <WithdrawForm 
                accessToken={betmateAccessToken}
                amount={amountToWithdraw}
                forename={betmateUserForename} 
                surname={betmateUserSurname} 
              />
            </React.Fragment>
          }
        </Paper>
      </main>
    </React.Fragment>
  );
}