import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { BetmateApiBaseUrl } from '../config/Urls';

import ProcessWithdrawRequest from '../interfaces/ProcessWithdrawRequest';
import ProcessWithdrawRequestResponse from '../interfaces/ProcessWithdrawRequestResponse';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
  },
}));

interface WithdrawFormProps {
  accessToken: string | null;
  amount: number | null;
  forename: string;
  surname: string;
}

export default function WithdrawForm(props: WithdrawFormProps) {

  const [forename, setForename] = useState<string>(props.forename);
  const [forenameError, setForenameError] = useState<string | null>(null);

  const onForenameChange = (event: any) => { 
    const input = event.target.value;
    setForename(input); 

    var noNumbersAllowed = /^([^0-9]*)$/;
    let isInputValid = input.match(noNumbersAllowed) && input.length < 40;

    setForenameError(isInputValid ? null : "Invalid Forename");
  }

  const [surname, setSurname] = useState<string>(props.surname);
  const [surnameError, setSurnameError] = useState<string | null>(null);

  const onSurnameChange = (event: any) => { 
    const input = event.target.value;
    setSurname(input); 

    var noNumbersAllowed = /^([^0-9]*)$/;
    let isInputValid = input.match(noNumbersAllowed) && input.length < 40;

    setSurnameError(isInputValid ? null : "Invalid Surname");
  }

  
  const [cardNumber, setCardNumber] = useState<string>("");
  const [cardNumberError, setCardNumberError] = useState<string | null>(null);
  
  const onCardNumberChange = (event: any) => { 
    const input = event.target.value;
    setCardNumber(input); 

    var numbersOnlyLengthOfSixteen = /^[0-9]{16}$/;
    var isMasterCardDebit = input.startsWith(5);
    let isInputValid = input.match(numbersOnlyLengthOfSixteen) && isMasterCardDebit;

    if (isInputValid) {
      setCardNumberError(null);
    } else {
      setCardNumberError(
        isMasterCardDebit ? 
        "Invalid card number" : 
        "Only MasterCard debit withdrawals are supported currently."
      );
    }
  }
  
  const [expiryMonth, setExpiryMonth] = useState<string>("");
  const [expiryMonthError, setExpiryMonthError] = useState<string | null>(null);
  
  const onExpiryMonthChange = (event: any) => { 
    const input = event.target.value;
    setExpiryMonth(input); 

    var numbersOnlyLengthOfTwo = /^[0-9]{2}$/;
    let isInputValid = input.match(numbersOnlyLengthOfTwo) && Number(input) >=1 && Number(input) <= 12;
    
    setExpiryMonthError(isInputValid ? null : "Invalid expiry month");
  }

  const [expiryYear, setExpiryYear] = useState<string>("");
  const [expiryYearError, setExpiryYearError] = useState<string | null>(null);
  
  const onExpiryYearChange = (event: any) => { 
    const input = event.target.value;
    setExpiryYear(input);

    var numbersOnlyLengthOfTwo = /^[0-9]{2}$/;
    let isInputValid = input.match(numbersOnlyLengthOfTwo) && Number(input) >=23 && Number(input) <= 33;

    setExpiryYearError(isInputValid ? null : "Invalid expiry year");
  }

  const [securityCode, setSecurityCode] = useState<string>("");
  const [securityCodeError, setSecurityCodeError] = useState<string | null>(null);


  const onSecurityCodeChange = (event: any) => { 
    const input = event.target.value;
    setSecurityCode(input); 

    var numbersOnlyLengthOfThree = /^[0-9]{3}$/;
    let isInputValid = input.match(numbersOnlyLengthOfThree);

    setSecurityCodeError(isInputValid ? null : "Invalid security code");
  }
  
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  useEffect(() => {
    const isFormValid = 
      !!forename && !forenameError &&
      !!surname && !surnameError &&
      !!cardNumber && !cardNumberError &&
      !!expiryMonth && !expiryMonthError &&
      !!expiryYear && !expiryYearError &&
      !!securityCode && !securityCodeError;

      setIsButtonEnabled(isFormValid);
  }, [
    forename, forenameError,
    surname, surnameError,
    cardNumber, cardNumberError,
    expiryMonth, expiryMonthError,
    expiryYear, expiryYearError,
    securityCode, securityCodeError
  ]);


  
  const [isFormProcessing, setIsFormProcessing] = useState<boolean>(false);
  const [withdrawSuccess, setWithdrawSuccess] = useState<boolean>(false);
  const [withdrawErrorMessage, setWithdrawErrorMessage] = useState<string | null>(null);

  const requestWithdrawl = async () => {
    try {
      if (!props.accessToken || !props.amount) {
        setWithdrawErrorMessage(
          "Something went wrong when trying to process your withdrawal, please contact support@betmate.app"
        )
        return;
      }

      setIsFormProcessing(true);

      const processWithdrawRequest: ProcessWithdrawRequest = {
        amount: props.amount,
        forename,
        surname,
        cardNumber,
        expiryMonth,
        expiryYear,
        securityCode
      };

      const processWithdrawRequestRequestInit: RequestInit = {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + props.accessToken,
        },
        body: JSON.stringify(processWithdrawRequest),
      };

      const processWithdrawRequestEndpoint = BetmateApiBaseUrl + "/ProcessWithdrawRequest";

      let response = await fetch(processWithdrawRequestEndpoint, processWithdrawRequestRequestInit);
      let typedResponse = await response.json() as ProcessWithdrawRequestResponse;

      if (typedResponse && typedResponse.success) {
        setWithdrawSuccess(true);
        setWithdrawErrorMessage(null);
      } else {
        setWithdrawSuccess(false);
        setWithdrawErrorMessage(typedResponse?.message ?? "Something went wrong processing your withdraw request");
      }
    } catch (e: any) {
      setWithdrawSuccess(false);
      setWithdrawErrorMessage(e?.message ?? "Something went wrong processing your withdraw request");
    } finally {
      setIsFormProcessing(false);
    }
  };
  
  const classes = useStyles();
  
  return (
    <React.Fragment>

      {/* Loading spinner */}
      {
        isFormProcessing === true && 
        <Grid container justifyContent="center"> 
          <CircularProgress size={100} />
        </Grid>
      }

      {/* Success */}
      {
        isFormProcessing === false && 
        withdrawErrorMessage === null &&
        withdrawSuccess &&
          <Grid item xs={12} md={10}>
            <Alert variant="filled" severity="success">
              <AlertTitle>Success</AlertTitle>
              {'Your request has been processed.\n'}
              <br/><br/>
              {'Please allow 3-5 working days for the money to appear in your bank account.'}
              <br/><br/>
              {'Press the back button to return to the app.'}
            </Alert>
          </Grid>
      }

      {/* Form */}
      {
        isFormProcessing === false && 
        withdrawSuccess === false && 
        withdrawErrorMessage === null &&
        <Grid container spacing={3}>

          {/* MasterCard only warning */}
          <Grid item xs={12} md={10}>
            <Alert severity="warning">
              Due to temporary issues with VISA & our payments provider, we are only able to process MasterCard debit withdrawals currently.
            </Alert>
          </Grid>

          {/* Error message */}
          {
            isFormProcessing === false && 
            withdrawErrorMessage !== null && 
              <Grid item xs={12} md={10}>
                <Alert variant="filled" severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {withdrawErrorMessage}
                </Alert>
              </Grid>
          }

          <Grid item xs={12} md={10}>
            <TextField
              required 
              id="cardForename" 
              label="Forename" 
              value={forename} 
              onChange={onForenameChange}
              error={!!forenameError}
              helperText={forenameError}
              fullWidth 
              autoComplete="cc-given-name"
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <TextField 
              required 
              id="cardSurname" 
              label="Surname" 
              value={surname} 
              onChange={onSurnameChange} 
              error={!!surnameError}
              helperText={surnameError}
              fullWidth 
              autoComplete="cc-family-name"
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <TextField
              required
              id="cardNumber"
              label="Card number"
              value={cardNumber} 
              onChange={onCardNumberChange} 
              error={!!cardNumberError}
              helperText={cardNumberError?? "16 digits"}
              fullWidth
              autoComplete="cc-number"
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <TextField 
              required 
              id="expDateMonth" 
              label="Expiry month" 
              value={expiryMonth} 
              onChange={onExpiryMonthChange} 
              error={!!expiryMonthError}
              helperText={expiryMonthError ?? "2 digits"}
              fullWidth 
              autoComplete="cc-exp-month"
              />
          </Grid>
          <Grid item xs={12} md={10}>
            <TextField 
              required 
              id="expDateYear" 
              label="Expiry year" 
              value={expiryYear} 
              onChange={onExpiryYearChange} 
              error={!!expiryYearError}
              helperText={expiryYearError ?? "2 digits"}
              fullWidth 
              autoComplete="cc-exp-year"
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <TextField
              required
              id="securitycode"
              label="Security code"
              value={securityCode} 
              onChange={onSecurityCodeChange}
              error={!!securityCodeError}
              helperText={securityCodeError ?? "3 digits"}
              fullWidth
              autoComplete="cc-csc"
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <Button
              variant="contained"
              color="primary"
              disabled={!isButtonEnabled}
              onClick={requestWithdrawl}
              className={classes.button}
              fullWidth
            >
              {'Request Withdrawal'}
            </Button>
          </Grid>
        </Grid>
      }

    </React.Fragment>
  );
}